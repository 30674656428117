/**
 * Métodos do componente ProductBatchUpload.
 * @module ProductBatchUpload
 * @typicalname methods
 */

import XLSX from "xlsx";

export default {
  readableSize(size) {
    const hr = require("@tsmx/human-readable");
    return hr.fromBytes(size);
  },
  fileDragOver(e) {
    this.uploadAreaClass.hover = true;
    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = "copy";
  },
  fileDragLeave(e) {
    e.preventDefault();
    this.uploadAreaClass.hover = false;
  },
  fileDragStart(e) {
    e.dataTransfer.dropEffect = "copy";
  },
  fileDrop(e) {
    this.fileLoader = true;
    e.stopPropagation();
    e.preventDefault();
    let files = e.dataTransfer.files; // Array of all files
    this.uploadAreaClass.hover = false;

    if (files.length > 0) {
      this.drawerWidth = 800;
      setTimeout(() => {
        files.forEach(async (file, i) => {
          this.files.push({
            name: file.name,
            size: file.size,
            //loading: true,
            loadError: false,
            key: file.size + "- loading",
          });

          this.showFilesList = true;
          await this.readExcel(file, i);
        });
      }, 100);
    } else {
      this.notification().error({erro: "Não foi possível carregar os arquivos"});
    }
  },

  /**
   * Função que lê excel para carga de produtos.
   * @function
   * @param {File} - Arquivo excel
   * @param {number} - Posição no array
   * @returns {void}
   */
  async readExcel2(file, i) {
    const vm = this;
    let reader = new FileReader();
    reader.onload = function(e) {
      let data = e.target.result;
      let workbook = XLSX.read(data, {
        type: "binary",
      });
      workbook.SheetNames.forEach(function(sheetName) {
        // Here is your object
        let XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName],
          {
            range: 1,
          },
        );
        let json_object = XL_row_object;
        vm.prods = json_object;
        vm.fileLoader = false;
        file.loading = false;
        file.key = "loaded";
        vm.files.splice(i, 1, file);
        console.log('final', vm.prods)
        // emite os produtos importados da tabela
        vm.$emit("produtos-importados", vm.prods, file.name);
      });
    };

    reader.onerror = function(ex) {
      this.notification().error({erro: "Erro ao ler arquivo"});
      file.loading = false;
      file.loadError = true;
      file.key = "error-loading";
      vm.files.splice(i, 1, file);
    };

    if (file && (file.name.includes(".xlsx") || file.name.includes(".xls"))) {
      reader.readAsBinaryString(file);
    } else {
      this.showFilesList = false;
      this.fileLoader = false;
      this.drawerWidth = 350;
      this.files.pop();
      this.notification().warn({ mensagem: "Somente arquivos .xlsx ou .xls são suportados"});
    }
  },
  async readExcel(file, i) {
    let vm = this;

    let jsZip = require("jszip");
    jsZip.loadAsync(file).then(function(zip) {
      vm.zipFiles = zip.files;
      Object.keys(zip.files).forEach((filename) => {
        let reader = new FileReader();

        reader.onload = function(e) {
          let data = e.target.result;
          let workbook = XLSX.read(data, {
            type: "binary",
          });

          workbook.SheetNames.forEach(function(sheetName) {
            // Here is your object
            let XL_row_object = XLSX.utils.sheet_to_row_object_array(
              workbook.Sheets[sheetName],
            );
            let json_object = XL_row_object;
            vm.trataProdutos(json_object, [], 0, 15);
          });

          file.loading = false;
          file.key = "loaded";
          vm.files.splice(i, 1, file);
        };

        reader.onerror = function(ex) {
          file.loading = false;
          file.loadError = true;
          file.key = "error-loading";
          vm.files.splice(i, 1, file);
        };

        if (
          !zip.files[filename].dir &&
          (filename.includes(".xlsx") || filename.includes(".xls"))
        ) {
          zip.files[filename].async("blob").then((fileData) => {
            reader.readAsBinaryString(fileData);
          });
        } else if (
          !zip.files[filename].dir &&
          !(filename.includes(".xlsx") || filename.includes(".xls")) &&
          !(filename.includes(".jpg") || filename.includes(".png"))
        ) {
          vm.showFilesList = false;
          vm.fileLoader = false;
          vm.drawerWidth = 350;
          vm.files.pop();
          vm.notification().warn(
            "Somente planilhas .xlsx ou .xls e imagens em .jpg ou .png são suportados",
          );
        }
      });
    });
  },
  async trataProdutos(json_object, prods, start, end) {
    const vm = this;

    if (start < json_object.length) {
      for (let i = start; i < end; i++) {
        const row = json_object[i];
        const images = row.Imagens ? row.Imagens.toString().split(",") : [];
        const imageFiles = [];

        await images.forEach(async (imageFilename) => {
          imageFilename = imageFilename.replace(" ", "");

          await vm.zipFiles[`Imagens/${imageFilename}`]
            .async("blob")
            .then((imageFileData) => {
              const imageReader = new FileReader();
              imageReader.onload = function(e) {
                let data = e.target.result;
                imageFiles.push({
                  _rFOTO: data.split(",")[1],
                  _rNM_ARQ: imageFilename,
                });
              };

              imageReader.readAsDataURL(imageFileData);
            });
        });

        setTimeout(() => {
          prods.push({
            ...row,
            imagens: imageFiles,
            carrousel: 0,
          });

          if ((i + 1) % end === 0) {
            if (i === 14) {
              vm.prods = prods;
              this.fileLoader = false;
            }

            vm.saveCargaProdutosPaginacao(prods).then(() => {
              vm.paginationLength++;
              prods = [];
              if (i < json_object.length) {
                end += 15;

                if (
                  i + 15 > json_object.length &&
                  !vm.reajusteTamanhoTrataProduto
                ) {
                  start = i + 1;
                  vm.reajusteTamanhoTrataProduto = true;
                } else {
                  start += 15;
                }

                if (end > json_object.length) {
                  end = json_object.length;
                }
                return vm.trataProdutos(json_object, prods, start, end);
              }
            });
          }
        }, 15);
      }
    } else {
      this.fileLoader = false;
    }
  },
  async saveCargaProdutosPaginacao(produtos) {
    const idbCargaProdutosPaginacao = await this.get("cargaProdutosPaginacao");
    if (
      idbCargaProdutosPaginacao &&
      idbCargaProdutosPaginacao.data.length > 0
    ) {
      const page =
        idbCargaProdutosPaginacao.data[
          idbCargaProdutosPaginacao.data.length - 1
        ].page + 1;
      const pagination = idbCargaProdutosPaginacao.data;
      pagination.push({ produtos, page });
      await this.save("cargaProdutosPaginacao", pagination);
    } else {
      await this.save("cargaProdutosPaginacao", [{ produtos, page: 1 }]);
    }
  },
  async changePageCargaProdutosPagination() {
    const idbCargaProdutosPaginacao = await this.get("cargaProdutosPaginacao");
    if (
      idbCargaProdutosPaginacao &&
      idbCargaProdutosPaginacao.data.length > 0
    ) {
      const pagina = idbCargaProdutosPaginacao.data.find(
        (p) => p.page === this.page,
      );
      this.prods = pagina.produtos;
    }
  },
  abreImg(imgProd) {
    this.abrindoImg = true;
    this.imgProdSelecionado = imgProd;
    this.imgDialog = true;
  },
  importarAqruivo() {
    const importar = document.getElementById("import");
    importar.click();
  },
  trataImportarArquivoBotao(files) {
    if (files.length > 0) {
      files.forEach(async (file, i) => {
        this.files.push({
          name: file.name,
          size: file.size,
          //loading: true,
          loadError: false,
          key: file.size + "- loading",
        });

        this.showFilesList = true;
        await this.readExcel2(file, i);
      });
      this.keyFileInput++;
    }
  },
  flatten(arr) {
    const vm = this;
    return arr.reduce(function(flat, toFlatten) {
      return flat.concat(
        Array.isArray(toFlatten) ? vm.flatten(toFlatten) : toFlatten,
      );
    }, []);
  },
  async enviarArquivo() {
    let usuario = await this.getUser();
    let produtosPlanilha = [];

    const idbProdutos = await this.get("cargaProdutosPaginacao");

    if (idbProdutos && idbProdutos.data.length > 0) {
      let produtos = idbProdutos.data.map((p) => {
        return p.produtos;
      });

      produtos = this.flatten(produtos);

      produtos.forEach((f) => {
        produtosPlanilha.push({
          _rDS_PROD: f.nomeProduto.toUpperCase(),
          _rSKU: f.sku,
          _rSITUAC: f.situacao,
          _rCD_GRP: f.grupo,
          _rVL_COMPRA: f.valorCompra,
          _rVL_VENDA: f.valorVenda,
          _rCD_DEPTO: f.departamento,
          _rCD_SECAO: f.secao,
          _rCD_COLEC: f.colecao,
          _rIM_ARQ: f.imagens,
          _rCD_OPESIS: usuario.base.us01_nr_usr,
        });
      });
      let valEnt = {
        _rPRODUTOS: produtosPlanilha,
      };
      try {
        await this.postSP("SP_CE360901012", [valEnt]);
        //this.getProdutos()
        this.openDrawer = false;
      } catch (err) {
        this.notification().error({erro: "Erro ao enviar as informações!"});
      }
      this.openDrawer = false;
    }
  },
  deleteProd(index) {
    this.prods.splice(index, 1);
    this.notification().success({mensagem:"Produto removido"});
  },
  removerArquivo(index) {
    this.files.splice(index, 1);
    this.prods = [];
    this.notification().success({mensagem:"Arquivo removido"});
    this.drawerWidth = 350;
  },
  closeUploadDrawer() {
    this.openDrawer = false;
    this.files = [];
    this.prods = [];
    this.reajusteTamanhoTrataProduto = false;
  },
  openUploadDrawer() {
    this.files = [];
    this.importarAqruivo();
  },
};
